.sticky {
  position: fixed !important;
  top: 0 !important;
}

.inside {
  z-index: 1000 !important;
}

.step-label {
  bottom: 2.15rem;
  position: absolute;
  white-space: nowrap;
}

.active-color {
  color: #1c9d5b !important;
  font-weight: 500 !important;
  white-space: nowrap !important;
}

.isActive {
  color: #1c9d5b !important;
}

.lastIndex {
  color: red !important;
}

.stepperIcons {
  gap: 10px;
  display: "flex";
  position: relative;
  align-items: start;
  flex-direction: column-reverse;
}
.percentageWrapper {
  width: 100%;
  position: relative;
}

@keyframes fadeIn {
  from {
    opacity: 0.9;
    transform: translateY(-6px); 
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 0.9;
    transform: translateY(0);
  }
  to {
    opacity: 1;
    transform: translateY(-12px); 
  }
}

.scrollFixed {
  position: fixed;
  top: 5rem;
  left: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease forwards; 
}

.scrollRelative {
  position: relative;
  top: 0;
  box-shadow: none;
  animation: fadeOut 0.6s ease forwards;
}

