.wrapper {
  @apply flex items-center flex-col gap-1;
}

.inputLabel {
  @apply text-sm font-tahoma-bold text-black ml-1.5 font-medium w-full dark:text-BrightGray;
}

.editableContainer {
  @apply flex justify-between items-center gap-x-4;
}

.textarea {
  @apply flex w-full items-center justify-center rounded-xl border border-gray-400 p-3 text-sm outline-none;
}
