@font-face {
  font-family: "Tahoma";
  src: local("Tahoma"), url("src/assets/font/Tahoma.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Tahoma-Bold";
  src: local("Tahoma-Bold"), url("src/assets/font/Tahoma-Bold.ttf") format("ttf");
  font-weight: bold;
  font-style: normal;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .registerHeading {
    @apply text-2xl text-SmokeyGray font-tahoma font-semibold text-center dark:text-BrightGray;
  }
  .flexCenter {
    @apply flex justify-center items-center;
  }
  .inputExtra {
    @apply flex items-center flex-col md:flex-row gap-1;
  }
  .inputExtraCss {
    @apply flex items-center flex-col gap-1;
  }
  .inputExtraLabel {
    @apply w-full md:w-[200px] lg:w-[260px] xl:w-[300px] 2xl:w-[340px];
  }
  .sideNavWrapper {
    @apply items-center border-b-SilverSand border-b;
  }
  .sideNavcontainer {
    @apply text-base tracking-wider py-4 font-semibold cursor-pointer flex items-center px-2 rounded-sm;
  }
  .sideNavcontainerChild {
    @apply text-base tracking-wider py-2 pl-2 font-semibold cursor-pointer flex items-center;
  }
  .navWrapper {
    @apply md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-80 2xl:w-[21rem] z-20 py-4 px-6 dark:bg-AFPDarkBgLight dark:text-white;
  }

  /* common styling */
  .textStyle {
    @apply text-SmokeyGray text-[16px] font-normal dark:text-BrightGray;
  }
  .h2Heading {
    @apply text-AFPHeading text-2xl font-semibold;
  }

  .flexCenter {
    @apply flex justify-center items-center;
  }

  .containerLayout {
    @apply text-black relative md:pt-[4.5rem] pb-0 py-8 min-h-screen bg-AFPBGPrimary dark:bg-AFPDarkBgDarkest dark:text-BrightGray;
  }

  /* login page styling */

  .loginwrap {
    @apply min-h-screen w-full bg-black flex justify-center items-center dark:bg-AFPDarkBgLight;
  }
  .loginwcontainer {
    @apply min-h-[96vh] md:min-h-[80vh] w-[96%] md:w-[92%] lg:w-[88%] my-2 md:my-4 lg:my-8 flex justify-center items-center;
  }
  .loginleftwrap {
    @apply col-span-5 md:col-span-3 lg:col-span-2 min-h-[90vh] bg-white rounded-bl-xl rounded-tl-xl rounded-br-xl rounded-tr-xl md:rounded-br-none md:rounded-tr-none dark:bg-AFPDarkBgDarkest;
  }
  .loginrightwrap {
    @apply md:col-span-2 lg:col-span-3 bg-gray-500 loginBG min-h-[90vh] hidden md:block rounded-br-xl rounded-tr-xl;
  }
  .defaultBtn {
    @apply text-white bg-AFPPrimary hover:bg-AFPPrimaryDark focus:outline-none font-medium rounded-lg hover:font-tahoma-bold text-center w-full px-5 py-3.5;
  }

  /* register page */

  .registerwrap {
    @apply flex flex-col w-[98%] md:w-3/4 justify-center items-center shadow-lg p-4 md:p-12 rounded-xl bg-white my-12 dark:bg-AFPDarkBgDarkest;
  }
  .registerSuccessDivider {
    @apply col-span-1 rounded-bl-xl rounded-tl-xl rounded-br-xl rounded-tr-xl md:rounded-br-none md:rounded-tr-none;
  }

  .warning {
    @apply text-red-500 text-sm py-1 px-3;
  }

  .standardmodal{
    @apply !p-4 !top-1/2 !left-1/2 !w-[90%] !max-w-[700px] !shadow-2xl bg-AFPBGPrimary !rounded-[12px] !absolute !border !border-AFPDisabled !transform !-translate-x-1/2 !-translate-y-1/2 dark:bg-AFPDarkBgDarkest dark:!border-SmokeyGray
  }

  .standardSecondModal{
    @apply top-1/2 left-1/2 w-[90%] max-w-[840px] outline-none bg-AFPBGPrimary mb-[50px] rounded-[12px] relative pb-[20px] transform -translate-x-1/2 -translate-y-1/2 dark:bg-AFPDarkBgLight dark:border-SmokeyGray
  }
}

@layer utilities {
  .content-auto {
    font-size: 3rem;
  }
  .shadow-footer {
    box-shadow: 1px 1px 1px 1px #706f6f;
  }
}

.loginBG {
  background-image: url("src/assets/img/LoginBG.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.custom-scrollbar::-webkit-scrollbar {
  display: none;
}

/* type number hide icons */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

/* custom shadow */
.custom-shadow {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

/* custom scrollbar */
.customScrollBar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
.customScrollBar::-webkit-scrollbar {
  width: 4px;
}
.customScrollBar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(163, 29, 26, 0.3);
  background-color: #a31d1a;
}
